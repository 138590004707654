'use client'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Scrollbar } from 'swiper/modules'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { SparklesIcon } from '@heroicons/react/24/outline'

import { Country } from '@/types'
import { Lang } from '@/i18n'
import { Container } from '@/components/layout'

import { useSearch } from '../search-provider'
import SortBy from './sort-by'
import Searchbar from './searchbar'
import Filters from './filters'
import CountryFilterItem from './country-filter-item'
import OtherFilterItem from './other-filter-item'

import 'swiper/css'
import 'swiper/css/scrollbar'
import '../style.css'

interface PopularCountriesProps {
  lang: Lang
  countries: Country[]
  regions: Country[]
  ids?: string[]
  collection?: string
  capacity?: string
  validity?: string
}

const Header = ({ lang, countries, regions, ids, collection }: PopularCountriesProps) => {
  const { t } = useTranslation()
  const { filters, handleSetSort, handleChangeCountry, handleChangeValidity, handleChangeCapacity } = useSearch()
  const selected = filters.countries.length > 0 || filters.capacity.length > 0 || filters.validity.length > 0
  const disableFiltering = !!ids?.length ? true : !selected
  const displayFilterText = collection ? t('Search.Text.PromotedOffers') : selected ? '' : t('Search.Text.BestOffers')

  return (
    <Container as='section' className='relative my-4 md:my-6 justify-center flex flex-col w-full'>
      <div className='flex flex-col gap-4 lg:gap-6 bg-gray-100'>
        <div className='flex justify-between lg:justify-center gap-x-4 md:gap-x-6'>
          <div className='flex-1 items-center w-full md:max-w-md'>
            <Searchbar lang={lang} disabled={!!ids?.length} countries={filters.countries} regions={regions} options={countries} handleChange={handleChangeCountry} />
          </div>
          <Filters disabled={disableFiltering} />
        </div>

        <div className='flex flex-col lg:flex-row md:items-start lg:items-center gap-4'>
          <div className='flex-1'>
            <div className='grid grid-cols-[minmax(0,1fr)] overflow-clip'>
              <div className='flex items-center gap-2 md:gap-4'>
                <Swiper
                  wrapperClass='gap-2'
                  slidesPerView='auto'
                  spaceBetween={8}
                  scrollbar={{
                    hide: false,
                    draggable: true,
                    dragSize: 30,
                    horizontalClass: 'filters-horizontal-scroll',
                  }}
                  modules={[Scrollbar]}
                  className='!m-0'
                >
                  {displayFilterText && (
                    <SwiperSlide className={clsx('!w-auto !m-0')}>
                      <div
                        className={clsx(
                          `inline-flex justify-center items-center max-w-72 px-4 py-1.5 rounded-full gap-2 text-sm leading-5 font-semibold border`,
                          collection ? 'bg-yellow-50 border-yellow-600 text-yellow-800' : 'bg-green-50 border-green-600 text-green-600',
                        )}
                      >
                        <SparklesIcon className='w-6 h-6' />
                        <span className='truncate'>{displayFilterText}</span>
                      </div>
                    </SwiperSlide>
                  )}
                  {disableFiltering
                    ? null
                    : filters.countries.map(country => {
                        return (
                          <SwiperSlide className={clsx('!w-auto !m-0')} key={country.code}>
                            <CountryFilterItem country={country} handleChange={country => (disableFiltering ? null : handleChangeCountry(country))} />
                          </SwiperSlide>
                        )
                      })}
                  {/*{filters.countries.length === 0 ? (
                    <div
                      className={clsx(`flex justify-center items-center max-w-72 px-4 py-1.5 bg-green-50 rounded-full gap-2 text-sm leading-5 font-semibold border border-green-600 text-green-600`)}
                    >
                      <SparklesIcon className='w-6 h-6' />
                      <span className='truncate'>{t('Search.Text.BestOffers')}</span>
                    </div>
                  ) : (
                    filters.countries.map(country => {
                      return (
                        <SwiperSlide className={clsx('!w-auto !m-0')} key={country.code}>
                          <CountryFilterItem country={country} handleChange={country => (disableFiltering ? null : handleChangeCountry(country))} />
                        </SwiperSlide>
                      )
                    })
                  )}*/}
                  {filters.capacity.map((option, index) => {
                    return (
                      <SwiperSlide className={clsx('!w-auto !m-0')} key={option.value}>
                        <OtherFilterItem text={t(option.label)} option={option} handleChange={() => handleChangeCapacity(option)} />
                      </SwiperSlide>
                    )
                  })}

                  {filters.validity.map((option, index) => {
                    return (
                      <SwiperSlide className={clsx('!w-auto !m-0')} key={option.value}>
                        <OtherFilterItem text={t(option.label)} option={option} handleChange={() => handleChangeValidity(option)} />
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>
            </div>
          </div>
          <div className='flex-1 lg:flex-none w-full lg:w-auto'>
            <SortBy bestOffers={!filters.countries.length} loading={filters.loading} count={filters.count} showing={filters.showing} sortBy={filters.sortBy} setSort={handleSetSort} />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Header
