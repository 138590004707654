import { useMemo } from 'react'

import Select, { ClassNamesConfig, components, InputProps, MenuProps, OptionProps, SingleValueProps, StylesConfig, ValueContainerProps } from 'react-select'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { CheckIcon } from '@heroicons/react/24/outline'

import { Lang } from '@/i18n'
import { Country, PlanSearchType } from '@/types'
import CountryFlag from '@/components/country-flag/country-flag'

import { filterCountries } from '../helpers'
import { SELECTED_LIMIT } from '../constants'

interface SearchbarProps {
  lang: Lang
  countries: Country[]
  regions: Country[]
  options: Country[]
  handleChange: (countries: Country) => void
  disabled?: boolean
}

type IsMulti = false

const instanceId = '1'
const countryQueryStringName = 'codes'

const styles: StylesConfig<Country, IsMulti> = {}

const classNames: ClassNamesConfig<Country, IsMulti> = {
  menu: () => `!z-50`,
  control: () => `!rounded-full`,
  menuList: () => `min-h-[500px]`,
  placeholder: () => 'truncate',
  indicatorsContainer: () => `hidden md:block`,
  indicatorSeparator: () => `hidden md:block`,
}

const Menu = (props: MenuProps<Country, IsMulti>) => {
  return <components.Menu {...props}>{props.children}</components.Menu>
}

const ValueContainer = (props: ValueContainerProps<Country, IsMulti>) => {
  return <components.ValueContainer {...props}>{props.children}</components.ValueContainer>
}

const SingleValue = (props: SingleValueProps<Country, IsMulti>) => {
  return (
    <components.SingleValue {...props} className='!text-gray-400'>
      {props.selectProps.placeholder}
    </components.SingleValue>
  )
}

const Option = (props: OptionProps<Country, IsMulti>) => {
  const { children, innerProps, ...rest } = props
  return (
    <div className={clsx('relative flex items-center pl-3 pr-9 py-2', props.isSelected ? 'font-bold text-black bg-gray-100 hover:bg-gray-200' : 'hover:bg-gray-200')} {...innerProps}>
      <div className='flex items-center gap-4'>
        <CountryFlag country={props.data} />
        {children}
      </div>
      {props.isSelected && (
        <span className='absolute top-0 bottom-0 right-0 flex items-center pr-4'>
          <CheckIcon className='size-5' />
        </span>
      )}
    </div>
  )
}

const NoOptionsMessage = () => {
  const { t } = useTranslation()
  return (
    <div className='p-4 md:p-6 w-full flex items-center flex-wrap justify-center gap-10'>
      <div className='grid gap-4'>
        <div>
          <h2 className='text-center text-black text-base font-semibold leading-relaxed pb-1'>{t('Search.Heading.NoResults')}</h2>
          <p className='text-center text-black text-sm font-normal leading-snug pb-4'>{t('Search.Text.NoResults')}</p>
          <div className='flex justify-center gap-3'>
            <a href='mailto:support@esimfly.app'>
              <button className='px-4 py-2 bg-white text-red-500 border border-red-500 rounded-full transition-all duration-500'>{t('Search.Action.NoResultsContact')}</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const Input = ({ children, ...props }: InputProps<Country, IsMulti>) => {
  return <components.Input {...props} aria-activedescendant='' />
}

const Searchbar = ({ disabled, countries, options, regions, handleChange }: SearchbarProps) => {
  const { t } = useTranslation()
  const placeholder = countries.length >= SELECTED_LIMIT ? t('Search.Text.CountriesPlaceholderMaxLimit') : t('Search.Text.CountriesPlaceholder')

  const optionsGrouped = useMemo(() => {
    let opt = [
      {
        label: t('Search.Text.AllRegions'),
        options: regions.filter(region => !countries.find(country => country.code === region.code)),
      },
      {
        label: t('Search.Text.AllCountries'),
        options: options.filter(option => !countries.find(country => country.code === option.code)),
      },
    ]

    if (countries.length) {
      opt.unshift({
        label: t('Search.Text.YourSelection', { count: countries.length }),
        options: countries,
      })
    }
    return opt
  }, [countries])

  return (
    <div className='z-50'>
      <Select
        instanceId={instanceId}
        placeholder={placeholder}
        styles={styles}
        classNames={classNames}
        className='w-full'
        components={{
          Input,
          ValueContainer,
          SingleValue,
          Menu,
          Option,
          NoOptionsMessage,
        }}
        isDisabled={countries.length >= SELECTED_LIMIT || disabled}
        isOptionDisabled={() => countries.length >= SELECTED_LIMIT}
        filterOption={(option, inputValue) => filterCountries(option.data.name.toLowerCase(), inputValue.toLowerCase())}
        formatOptionLabel={data => data.name}
        getOptionValue={data => data.code}
        value={countries}
        inputId={countryQueryStringName}
        options={optionsGrouped}
        onChange={(value, actionMeta) => {
          if (value) {
            handleChange(value)
          }
        }}
      />
    </div>
  )
}

export default Searchbar
