'use client'

import { createContext, ReactNode, startTransition, useContext, useEffect, useState } from 'react'
import { useRouter, useSearchParams } from 'next/navigation'

import { OptionItem } from '@/types/common'
import { Country } from '@/types'
import { Lang } from '@/i18n'

import { validityOptions, capacityOptions, sortOptions, SELECTED_LIMIT } from './constants'
import { CapacityOptionItem, SortOptionItem, ValidityOptionItem } from './types'
import { boolean } from 'zod'

interface SearchProviderProps {
  lang: Lang
  countries: Array<Country>
  regions: Array<Country>
  children: ReactNode
  codes?: string
  sortBy?: string
  capacity?: string
  validity?: string
}

export interface SearchData {
  loading: boolean
  count: number
  showing: number
  countries: Array<Country>
  sortBy: SortOptionItem
  validity: Array<ValidityOptionItem>
  capacity: Array<CapacityOptionItem>
}

const SearchContext = createContext({
  codes: Array<string>(),
  filters: {
    loading: true,
    count: 0,
    showing: 0,
    countries: Array<Country>(),
    sortBy: sortOptions[0],
    validity: Array<ValidityOptionItem>(),
    capacity: Array<CapacityOptionItem>(),
  },
  setFilters: (action: SearchData | ((prevState: SearchData) => SearchData)) => {},
  handleChangeCountry: (country: Country) => {},
  handleChangeCapacity: (capacity: CapacityOptionItem) => {},
  handleChangeValidity: (validity: ValidityOptionItem) => {},
  handleSetSort: (sortBy: SortOptionItem) => {},
  handleSetFilters: ({ capacity, validity }: { capacity: CapacityOptionItem[]; validity: ValidityOptionItem[] }) => {},
})

function getInitialCountries({ countryCodes, countries, regions }: { countryCodes: string[]; countries: Country[]; regions: Country[] }): Country[] {
  if (!countryCodes || countryCodes.length === 0) {
    return []
  }
  const codes = countryCodes.slice(0, SELECTED_LIMIT)
  const selectedCountries: Country[] = []
  for (const code of codes) {
    const country = countries.find(c => c.code === code)
    if (country) {
      selectedCountries.push(country)
    } else {
      const country = regions.find(c => c.code === code)
      if (country) {
        selectedCountries.push(country)
      }
    }
  }
  return selectedCountries
}

const SearchProvider = ({ children, lang, countries, regions, codes: _codes, sortBy, capacity, validity }: SearchProviderProps) => {
  const router = useRouter()
  const [codes, setCodes] = useState<string[]>(_codes?.split(',')?.map(x => x.trim()) || [])
  const [sort, setSort] = useState(sortOptions.find(option => option.value === sortBy) || sortOptions[0])
  const [filters, setFilters] = useState<SearchData>({
    loading: true,
    count: 0,
    showing: 0,
    countries: getInitialCountries({ countryCodes: codes, countries, regions }),
    sortBy: sortOptions.find(option => option.value === sortBy) || sortOptions[0],
    validity: validityOptions.filter(option => validity?.includes(option.value)),
    capacity: capacityOptions.filter(option => capacity?.includes(option.value)),
  })
  const searchParams = useSearchParams()

  useEffect(() => {
    let _cc: Country[] = []
    const codes = searchParams.get('codes')
    if (codes) {
      const countryCodes = codes.split(',')
      countryCodes.forEach(code => {
        const region = regions.find(c => c.code === code)
        if (region) {
          _cc.push(region)
        } else {
          const country = countries.find(c => c.code === code)
          if (country) {
            _cc.push(country)
          }
        }
      })
    }

    const capacity = searchParams.get('capacity')
    let _c: CapacityOptionItem[] = []
    if (capacity) {
      _c = capacityOptions.filter(c => capacity.split(',').includes(c.value))
    }

    const validity = searchParams.get('validity')
    let _v: ValidityOptionItem[] = []
    if (validity) {
      _v = validityOptions.filter(c => validity.split(',').includes(c.value))
    }

    const sortBy = searchParams.get('sortBy')
    const _s = sortOptions.find(option => option.value === sortBy) || sortOptions[0]

    setFilters(prevState => ({ ...prevState, countries: _cc, sortBy: _s, capacity: _c, validity: _v }))
  }, [searchParams])

  const handleChangeCountry = (country: Country) => {
    const searchParams = new URLSearchParams(document.location.search)
    const alreadySelected = filters.countries.some(c => c.code === country.code)
    const newCountries = alreadySelected ? filters.countries.filter(c => c.code !== country.code) : [...filters.countries, country]
    // setResults(prevState => ({ ...prevState, loading: true }))
    setFilters(prevState => {
      if (newCountries.length > 0) {
        return { ...prevState, countries: newCountries, loading: true }
      } else {
        return { ...prevState, countries: [], validity: [], capacity: [], loading: true }
      }
    })
    // setResults(prevState => ({ ...prevState, loading: true }))

    startTransition(() => {
      if (newCountries.length > 0) {
        searchParams.set('codes', newCountries.map(c => c.code).join(','))
      } else {
        searchParams.delete('codes')
        searchParams.delete('validity')
        searchParams.delete('capacity')
      }
      router.push(`/${lang}/offers?${searchParams.toString()}`)
      // setFilters(prevState => ({ ...prevState, loading: true }))
    })
  }

  const handleChangeCapacity = (capacity: CapacityOptionItem) => {
    const searchParams = new URLSearchParams(document.location.search)
    const alreadySelected = filters.capacity.some(c => c.value === capacity.value)
    const newCapacity = alreadySelected ? filters.capacity.filter(c => c.value !== capacity.value) : [...filters.capacity, capacity]
    // setFilters(prevState => ({ ...prevState, capacity: newCapacity }))

    if (newCapacity.length > 0) {
      searchParams.set('capacity', newCapacity.map(c => c.value).join(','))
    } else {
      searchParams.delete('capacity')
    }
    window.history.pushState({}, '', `/${lang}/offers?${searchParams.toString()}`)

    // setResults(prevState => ({ ...prevState, loading: true }))
    // startTransition(() => {
    //   if (newCapacity.length > 0) {
    //     searchParams.set('capacity', newCapacity.map(c => c.value).join(','))
    //   } else {
    //     searchParams.delete('capacity')
    //   }
    //   router.push(`/${lang}/offers?${searchParams.toString()}`)
    // })
  }

  const handleChangeValidity = (validity: ValidityOptionItem) => {
    const searchParams = new URLSearchParams(document.location.search)
    const alreadySelected = filters.validity.some(c => c.value === validity.value)
    const newValidity = alreadySelected ? filters.validity.filter(c => c.value !== validity.value) : [...filters.validity, validity]
    // setFilters(prevState => ({ ...prevState, validity: newValidity }))

    if (newValidity.length > 0) {
      searchParams.set('validity', newValidity.map(c => c.value).join(','))
    } else {
      searchParams.delete('validity')
    }
    window.history.pushState({}, '', `/${lang}/offers?${searchParams.toString()}`)

    // setResults(prevState => ({ ...prevState, loading: true }))
    //
    // startTransition(() => {
    //   if (newvalidity.length > 0) {
    //     searchParams.set('validity', newvalidity.map(c => c.value).join(','))
    //   } else {
    //     searchParams.delete('validity')
    //   }
    //   router.push(`/${lang}/offers?${searchParams.toString()}`)
    // })
  }

  const handleSetFilters = ({ capacity, validity }: { capacity: CapacityOptionItem[]; validity: ValidityOptionItem[] }) => {
    const searchParams = new URLSearchParams(document.location.search)
    if (capacity.length > 0) {
      capacity.sort((x, y) => Number(x.value) - Number(y.value))
      searchParams.set('capacity', capacity.map(c => c.value).join(','))
    } else {
      searchParams.delete('capacity')
    }
    if (validity.length > 0) {
      validity.sort((x, y) => Number(x.value) - Number(y.value))
      searchParams.set('validity', validity.map(c => c.value).join(','))
    } else {
      searchParams.delete('validity')
    }
    // setFilters(prevState => ({ ...prevState, capacity, validity }))
    window.history.pushState({}, '', `/${lang}/offers?${searchParams.toString()}`)

    // setResults(prevState => ({ ...prevState, loading: true }))
    // startTransition(() => {
    //   router.push(`/${lang}/offers?${searchParams.toString()}`)
    // })
  }

  const handleSetSort = (sortBy: OptionItem) => {
    const searchParams = new URLSearchParams(document.location.search)
    searchParams.set('sortBy', sortBy.value)
    // setSort(sortBy)
    window.history.pushState({}, '', `/${lang}/offers?${searchParams.toString()}`)

    // setResults(prevState => ({ ...prevState, loading: true }))
    // startTransition(() => {
    //   router.push(`/${lang}/offers?${searchParams.toString()}`)
    // })
  }

  return (
    <SearchContext.Provider value={{ codes, filters, setFilters, handleChangeCountry, handleChangeValidity, handleChangeCapacity, handleSetFilters, handleSetSort }}>{children}</SearchContext.Provider>
  )
}

export const useSearch = () => {
  const context = useContext(SearchContext)
  if (context === undefined) {
    throw new Error('useSearch must be used within a SearchProvider')
  }
  return context
}

export default SearchProvider
