import { GlobeEuropeAfricaIcon, GlobeAmericasIcon, GlobeAsiaAustraliaIcon } from '@heroicons/react/24/outline'

import { Country } from '@/types'
import clsx from 'clsx'

interface CountryFlagProps {
  country: Country
  className?: string
  size?: 'sm' | 'md'
}

const BalkanIcon = () => (
  <div className={clsx('flex items-center justify-center w-5 h-5 border-[1.5px] rounded-full border-black')}>
    <svg width='20' height='20' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.73858 18.9175L7.83382 19.2033L8.16715 19.0604L8.45285 19.4652L8.76239 19.6318L8.90524 20.1794L8.78619 20.7032L9.02428 21.3461L9.57188 21.7032L9.59571 22.108L9.19094 22.3223L9.16713 22.8223L8.64334 23.5603L8.42905 23.4651L8.38141 23.1318L7.64333 22.608L7.47668 21.8937L7.50048 20.8461L7.61953 20.3937L7.40524 20.1556L7.28619 19.6556L7.73858 18.9175Z'
        fill='currentColor'
      ></path>
      <path
        d='M18.357 17.3932L17.8094 18.0122L17.4998 19.0837L17.9998 19.9408L16.9046 19.7503L15.7142 20.2265L15.7856 20.9884L14.6903 21.1313L13.7618 20.5836L12.8094 21.0122L11.9047 20.9646L11.7142 19.9646L11.0475 19.4646L11.2142 19.2741L11.0713 19.0837L11.2142 18.6075L11.6428 18.1313L10.9761 17.4884L10.8094 16.917L11.0713 16.5837L11.4999 17.2027L11.9523 17.1075L12.9046 17.3218L14.7142 17.417L15.2618 17.0361L16.6665 16.6789L17.6189 17.2265L18.357 17.3932Z'
        fill='currentColor'
      ></path>
      <path
        d='M6.54761 15.6557L6.99999 15.6318L6.73809 16.2985L7.38093 16.8937L7.26189 17.5842L6.99999 17.6556L6.7857 17.7985L6.40476 18.1556L6.30952 18.989L5.16666 18.4175L4.66667 17.7747L4.16668 17.4413L3.57145 16.8699L3.26192 16.4175L2.61908 15.7033L2.80957 15.0842L3.28575 15.4414L3.52384 15.108L4.07144 15.0842L5.14286 15.3461L5.97618 15.3223L6.54761 15.6557Z'
        fill='currentColor'
      ></path>
      <path
        d='M15.809 21.7503L15.0471 21.7027L14.4042 21.5598L12.9281 21.9408L13.8804 22.7979L13.2852 23.0598H12.5947L11.8566 22.2741L11.6424 22.6074L12.0233 23.5122L12.7138 24.2265L12.2614 24.5598L13.0233 25.2503L13.6899 25.7026L13.7852 26.5598L12.5947 26.155L13.0233 26.9407L12.2376 27.0836L12.8328 28.4407L11.9995 28.4645L10.9043 27.7978L10.3328 26.5836L10.0233 25.5598L9.47572 24.8693L8.76144 23.9884L8.6424 23.5598L9.16619 22.8217L9.18999 22.3217L9.59477 22.1074L9.57093 21.7027L10.3805 21.5836L10.809 21.2503L11.4757 21.2741L11.6662 21.0122L11.9043 20.9646L12.809 21.0122L13.7614 20.5836L14.6899 21.1313L15.7852 20.9884L15.7137 20.2265L16.3566 20.6313L16.0947 21.5836L15.809 21.7503Z'
        fill='currentColor'
      ></path>
      <path
        d='M6.19072 14.0597L6.54786 14.6549L6.9526 15.0835L6.54786 15.6549L5.97643 15.3216L5.1431 15.3454L4.07169 15.0835L3.52409 15.1073L3.286 15.4407L2.80981 15.0835L2.61933 15.7026L3.26216 16.4168L3.5717 16.8692L4.16693 17.4406L4.66692 17.774L5.16691 18.4168L6.30976 18.9882L6.19072 19.2263L5.00025 18.6787L4.23837 18.1311L3.09551 17.6787L1.97649 16.5835L2.21458 16.4645L1.61935 15.8216L1.54791 15.3216L0.762191 15.0835L0.428884 15.7264L0.0479126 15.2264V14.7026L0.0717451 14.6788L0.928873 14.7264L1.11933 14.4883L1.54791 14.7264L2.02409 14.7502L2.00029 14.3454L2.40504 14.1788L2.47648 13.5835L3.40504 13.1788L3.78599 13.3692L4.73836 14.0121L5.76214 14.2978L6.19072 14.0597Z'
        fill='currentColor'
      ></path>
      <path
        d='M11.9046 20.9655L11.6665 21.0131L11.476 21.275L10.8094 21.2512L10.3808 21.5845L9.57127 21.7036L9.02368 21.3464L8.78558 20.7036L8.90463 20.1798L9.07128 20.2036L9.09509 19.8941L9.78556 19.656L10.0713 19.5846L10.476 19.5131L11.0475 19.4655L11.7141 19.9655L11.9046 20.9655Z'
        fill='currentColor'
      ></path>
      <path
        d='M8.35688 18.7261L8.16643 19.0595L7.83309 19.2023L7.73785 18.9166L7.28547 19.6547L7.40451 20.1547L7.14262 20.0357L6.73787 19.5357L6.19025 19.2261L6.30929 18.988L6.40454 18.1547L6.78548 17.7976L6.99977 17.6547L7.3331 17.9166L7.54736 18.1309L7.95214 18.2976L8.45213 18.6071L8.35688 18.7261Z'
        fill='currentColor'
      ></path>
      <path
        d='M15.0947 10.6071L15.4757 10.7737L15.9042 11.2023L16.3804 11.8213L17.1899 12.7261L17.3328 13.3689L17.2852 14.0118L17.5947 14.7023L18.1661 14.988L18.7137 14.7261L19.2851 14.988L19.3804 15.3927L18.8328 15.7022L18.4518 15.5594L18.3566 17.3927L17.6185 17.226L16.6661 16.6784L15.2614 17.0356L14.7138 17.4165L12.9042 17.3213L11.9519 17.107L11.4995 17.2022L11.0709 16.5832L10.8328 16.3213L11.0709 16.0594L10.7614 15.8927L10.4043 16.226L9.66619 15.7737L9.49953 15.1546L8.73762 14.8213L8.54716 14.3213L7.83289 13.7499L8.76145 13.4642L9.38049 12.4404L9.83287 11.4404L10.5233 11.1309L10.9995 10.7975L11.7614 10.9642H12.5233L13.1185 11.3451L13.4995 11.107L14.3566 10.9642L14.5947 10.6071H15.0947Z'
        fill='currentColor'
      ></path>
      <path
        d='M11.0711 16.5842L10.8093 16.9175L10.9759 17.4889L11.6426 18.1318L11.214 18.608L11.0711 19.0841L11.214 19.2746L11.0473 19.4651L10.4759 19.5127L10.0712 19.5841L9.99973 19.4651L10.1426 19.2984L10.2378 18.9175L10.0712 18.9413L9.80924 18.6556L9.59498 18.5842L9.4045 18.3461L9.16641 18.2508L8.97592 18.0365L8.76166 18.1318L8.64261 18.6318L8.35688 18.727L8.45213 18.608L7.95214 18.2984L7.54736 18.1318L7.3331 17.9175L6.99977 17.6556L7.26166 17.5842L7.38071 16.8937L6.73787 16.2985L6.99977 15.6318L6.54739 15.6556L6.95213 15.0842L6.54739 14.6556L6.19025 14.0604L7.07118 13.6794L7.83309 13.7509L8.54737 14.3223L8.73783 14.8223L9.49974 15.1556L9.66639 15.7747L10.4045 16.227L10.7616 15.8937L11.0711 16.0604L10.8331 16.3223L11.0711 16.5842Z'
        fill='currentColor'
      ></path>
      <path
        d='M3.40474 13.1795L2.47617 13.5843L2.40473 14.1795L1.99998 14.3462L2.02379 14.7509L1.5476 14.7271L1.11902 14.489L0.928567 14.7271L0.0714394 14.6795L0.333336 14.5604L0 13.9176L0.0952429 13.1795L1.09522 13.2986L1.66665 12.9414L2.71426 12.9176L2.92855 12.6557L3.11901 12.6795L3.40474 13.1795Z'
        fill='currentColor'
      ></path>
      <path
        d='M34.8096 20.6546L36.1191 20.5832L37.4524 21.3451L37.7619 21.8689L37.7857 22.607L38.7857 22.9879L39.3572 23.4165L38.5714 23.8688L39.2619 25.6069L39.0952 26.0831L40 27.2974L39.4286 27.5593L38.9286 27.1783L37.4286 26.9641L36.9286 27.2021L35.5239 27.4402L34.8334 27.4164L33.4763 27.9879H32.4286L31.7144 27.7021L30.3811 28.1307L29.9287 27.845L29.9763 28.6783L29.6906 29.0116L29.3811 29.345L28.8335 28.6545L29.2382 28.0831L28.4763 28.2259L27.3811 27.8688L26.6192 28.7497L24.7145 28.9164L23.5954 28.1069L22.2383 28.0593L22.0002 28.6783L21.1431 28.8688L19.8574 28.0593L18.4764 28.0831L17.5717 26.5593L16.5717 25.726L17.0479 24.5355L16.1907 23.7974L17.405 22.3451L19.3097 22.2974L19.6907 21.1308L22.0716 21.3451L23.405 20.3689L24.7859 19.9403L26.8097 19.8927L29.143 20.9641L31.0239 21.5593L32.4048 21.3213L33.5001 21.4641L34.8096 20.6546Z'
        fill='currentColor'
      ></path>
      <path
        d='M10.0714 19.5834L9.78564 19.6549L9.09516 19.893L9.07136 20.2025L8.90471 20.1787L8.76186 19.631L8.45233 19.4644L8.16663 19.0596L8.35708 18.7263L8.64281 18.6311L8.76186 18.1311L8.97612 18.0358L9.1666 18.2501L9.4047 18.3453L9.59518 18.5834L9.80944 18.6549L10.0714 18.9406L10.238 18.9168L10.1428 19.2977L9.99993 19.4644L10.0714 19.5834Z'
        fill='currentColor'
      ></path>
      <path d='M2.00014 29.3216L1.95251 29.393L1.83346 29.3454L1.71442 29.274V29.1549L1.69061 29.1311H1.83346L1.92871 29.2025L1.97634 29.2501L2.00014 29.3216Z' fill='currentColor'></path>
      <path d='M1.61911 29.0594L1.50006 29.0356V28.988L1.59527 28.9642L1.69052 29.0356L1.61911 29.0594Z' fill='currentColor'></path>
    </svg>
  </div>
)

const iconMapper: Record<string, JSX.Element> = {
  Europe: <GlobeEuropeAfricaIcon className='w-6 h-6' />,
  Asia: <GlobeAsiaAustraliaIcon className='w-6 h-6' />,
  Africa: <GlobeEuropeAfricaIcon className='w-6 h-6' />,
  'North America': <GlobeAmericasIcon className='w-6 h-6' />,
  'South America': <GlobeAmericasIcon className='w-6 h-6' />,
  Oceania: <GlobeAsiaAustraliaIcon className='w-6 h-6' />,
  Global: <GlobeAmericasIcon className='w-6 h-6' />,
  Balkan: <BalkanIcon />,
}

const CountryFlag = ({ country, size = 'sm' }: CountryFlagProps) => {
  const sizeClassName = size === 'sm' ? '!min-w-6 h-6' : 'min-w-7 h-7'

  const isRegion = country.code?.length > 2
  if (isRegion) {
    return <div className={clsx('flex items-center justify-center', sizeClassName)}>{iconMapper[country.code]}</div>
  }
  return <span className={clsx(`fi fi-${country.code}`, sizeClassName)} />
}

export default CountryFlag
