import { CapacityOptionItem, SortOptionItem, ValidityOptionItem } from './types'

export const REVALIDATE_HOURS = 60 * 60

// - is inverted  (asc -> desc, desc -> asc)
export const sortOptions: SortOptionItem[] = [
  {
    label: 'Search.Text.Cheapest',
    value: 'price=asc',
    prismaProperty: 'price',
    divider: true,
  },
  { label: 'Search.Text.MostData', value: 'capacity=desc', prismaProperty: '-volume' },
  { label: 'Search.Text.LeastData', value: 'capacity=asc', prismaProperty: 'volume', divider: true },
  { label: 'Search.Text.MostValidity', value: 'validity=desc', prismaProperty: '-validity' },
  { label: 'Search.Text.LeastValidity', value: 'validity=asc', prismaProperty: 'validity' },
]

export const SELECTED_LIMIT = 5
export const POPULAR_LIMIT = 5

// validityFromQueryParams.includes(option.value) avoid using similar values (stop at 9)

const MB = 1024
export const capacityOptions: CapacityOptionItem[] = [
  {
    value: '1',
    label: 'Search.Text.0_5_GB',
    config: [{ from: 0.1, to: 5 * MB }],
  },
  {
    value: '2',
    label: 'Search.Text.5_10_GB',
    config: [{ from: 5 * MB, to: 10 * MB }],
  },
  {
    value: '3',
    label: 'Search.Text.10_20_GB',
    config: [{ from: 10 * MB, to: 20 * MB }],
  },
  {
    value: '4',
    label: 'Search.Text.20+_GB',
    config: [
      { from: 0, to: 0 }, // Unlimited
      { from: 20 * MB, to: Number.POSITIVE_INFINITY },
    ],
  },
]

// validityFromQueryParams.includes(option.value) avoid using similar values (stop at 9)
export const validityOptions: ValidityOptionItem[] = [
  {
    value: '1',
    label: 'Search.Text.0_7_Days',
    config: [{ from: 0, to: 7 }],
  },
  {
    value: '2',
    label: 'Search.Text.7_15_Days',
    config: [{ from: 7, to: 15 }],
  },
  {
    value: '3',
    label: 'Search.Text.15_30_Days',
    config: [{ from: 15, to: 30 }],
  },
  {
    value: '4',
    label: 'Search.Text.30+_Days',
    config: [{ from: 30, to: Number.POSITIVE_INFINITY }],
  },
]
