const PriceIcon = ({ className }: { className?: string }) => {
  return (
    <svg className={className} viewBox='0 0 24 25' width='24' height='24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        opacity='0.4'
        d='M4.16989 15.8102L8.69989 20.3402C10.5599 22.2002 13.5799 22.2002 15.4499 20.3402L19.8399 15.9502C21.6999 14.0902 21.6999 11.0702 19.8399 9.20018L15.2999 4.68018C14.3499 3.73018 13.0399 3.22018 11.6999 3.29018L6.69989 3.53018C4.69989 3.62018 3.10989 5.21018 3.00989 7.20018L2.76989 12.2002C2.70989 13.5502 3.21989 14.8602 4.16989 15.8102Z'
      />
      <path d='M9.50012 12.8902C11.0907 12.8902 12.3801 11.6008 12.3801 10.0102C12.3801 8.41961 11.0907 7.13019 9.50012 7.13019C7.90954 7.13019 6.62012 8.41961 6.62012 10.0102C6.62012 11.6008 7.90954 12.8902 9.50012 12.8902Z' />
    </svg>
  )
}

export default PriceIcon
