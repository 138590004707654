const CountriesIcon = ({ className }: { className?: string }) => {
  return (
    <svg className={className} width='24' height='24' viewBox='0 0 24 24' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path d='M22.65 18.13C22.55 17.73 22.15 17.48 21.75 17.58C21.35 17.68 21.1 18.08 21.19 18.48C21.23 18.65 21.25 18.82 21.25 19C21.25 20.24 20.24 21.25 19 21.25C17.76 21.25 16.75 20.24 16.75 19C16.75 17.9 17.54 16.99 18.59 16.79C18.49 17.02 18.5 17.3 18.66 17.52C18.81 17.72 19.04 17.83 19.27 17.83C19.42 17.83 19.58 17.78 19.71 17.69L20.88 16.84C20.89 16.83 20.89 16.82 20.9 16.82C20.91 16.81 20.92 16.81 20.93 16.8C20.96 16.77 20.98 16.74 21 16.7C21.03 16.66 21.07 16.63 21.09 16.58C21.11 16.54 21.12 16.49 21.14 16.45C21.15 16.4 21.17 16.36 21.18 16.31C21.19 16.26 21.18 16.22 21.17 16.17C21.17 16.12 21.17 16.07 21.15 16.02C21.14 15.97 21.11 15.93 21.09 15.89C21.07 15.85 21.06 15.81 21.04 15.78C21.03 15.77 21.02 15.77 21.01 15.76C21 15.75 21 15.74 20.99 15.73L19.99 14.58C19.72 14.27 19.24 14.23 18.93 14.51C18.71 14.71 18.63 15 18.71 15.27C16.78 15.42 15.25 17.02 15.25 18.99C15.25 21.06 16.93 22.74 19 22.74C21.07 22.74 22.75 21.06 22.75 18.99C22.75 18.71 22.72 18.41 22.65 18.13Z' />
      <path
        opacity='0.4'
        d='M2.02975 14.63C2.02975 14.66 2.00977 14.7 2.00977 14.73C2.92977 16.57 4.42974 18.08 6.26974 18.99C6.29975 18.99 6.33975 18.97 6.36975 18.97C6.02975 17.81 5.76974 16.62 5.57974 15.43C4.37974 15.23 3.18975 14.97 2.02975 14.63Z'
      />
      <path opacity='0.4' d='M19.0701 6.42999C18.1301 4.45999 16.5401 2.87 14.5801 1.94C14.9401 3.13 15.2401 4.35001 15.4401 5.57001C16.6601 5.77001 17.8801 6.05999 19.0701 6.42999Z' />
      <path
        opacity='0.4'
        d='M1.93018 6.43002C3.13018 6.07002 4.35019 5.77004 5.57019 5.57004C5.77019 4.38004 6.02017 3.20004 6.36017 2.04004C6.33017 2.04004 6.29019 2.02002 6.26019 2.02002C4.38019 2.95002 2.85018 4.52002 1.93018 6.43002Z'
      />
      <path
        opacity='0.4'
        d='M13.8202 5.35999C13.5802 4.05999 13.2802 2.76 12.8502 1.5C12.8302 1.43 12.8302 1.37001 12.8202 1.29001C12.0802 1.11001 11.3002 0.98999 10.5002 0.98999C9.69018 0.98999 8.9202 1.10001 8.1702 1.29001C8.1602 1.36001 8.17018 1.42 8.15018 1.5C7.73018 2.76 7.42018 4.05999 7.18018 5.35999C9.39018 5.11999 11.6102 5.11999 13.8202 5.35999Z'
      />
      <path
        opacity='0.4'
        d='M5.36023 7.17999C4.05023 7.41999 2.76024 7.71999 1.50024 8.14999C1.43024 8.16999 1.37025 8.16999 1.29025 8.17999C1.11025 8.91999 0.990234 9.7 0.990234 10.5C0.990234 11.31 1.10025 12.08 1.29025 12.83C1.36025 12.84 1.42024 12.83 1.50024 12.85C2.76024 13.27 4.05023 13.58 5.36023 13.82C5.12023 11.61 5.12023 9.38999 5.36023 7.17999Z'
      />
      <path
        opacity='0.4'
        d='M19.6999 8.17999C19.6299 8.17999 19.5699 8.16999 19.4899 8.14999C18.2299 7.72999 16.9299 7.41999 15.6299 7.17999C15.8799 9.38999 15.8799 11.61 15.6299 13.81C16.9299 13.57 18.2299 13.27 19.4899 12.84C19.5599 12.82 19.6199 12.83 19.6999 12.82C19.8799 12.07 19.9999 11.3 19.9999 10.49C19.9999 9.69999 19.8899 8.92999 19.6999 8.17999Z'
      />
      <path
        opacity='0.4'
        d='M7.18018 15.64C7.42018 16.95 7.72018 18.24 8.15018 19.5C8.17018 19.57 8.1602 19.63 8.1702 19.71C8.9202 19.89 9.69018 20.01 10.5002 20.01C11.3002 20.01 12.0802 19.9 12.8202 19.71C12.8302 19.64 12.8302 19.58 12.8502 19.5C13.2702 18.24 13.5802 16.95 13.8202 15.64C12.7202 15.76 11.6102 15.85 10.5002 15.85C9.39018 15.84 8.28018 15.76 7.18018 15.64Z'
      />
      <path
        opacity='0.4'
        d='M6.9501 6.94998C6.6501 9.30998 6.6501 11.69 6.9501 14.06C9.3101 14.36 11.6901 14.36 14.0601 14.06C14.3601 11.7 14.3601 9.31998 14.0601 6.94998C11.6901 6.64998 9.3101 6.64998 6.9501 6.94998Z'
      />
    </svg>
  )
}

export default CountriesIcon
