import { Fragment, useEffect, useState } from 'react'
import { Button, Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import clsx from 'clsx'
import { FunnelIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { FunnelIcon as FunnelIconMobile } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'

import CapacityIcon from '@/components/icons/capacity'
import ValidityIcon from '@/components/icons/validity'

import { capacityOptions, validityOptions } from '../../constants'
import { useSearch } from '../../search-provider'
import { CapacityOptionItem, ValidityOptionItem } from '../../types'
import FilterList from './filter-list'

const Filters = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation()
  const { filters, handleSetFilters } = useSearch()
  const [open, setOpen] = useState(false)
  const [capacity, setCapacity] = useState<ValidityOptionItem[]>([])
  const [validity, setValidity] = useState<CapacityOptionItem[]>([])

  useEffect(() => {
    if (open) {
      setCapacity(filters.capacity)
      setValidity(filters.validity)
    }
  }, [open, filters])

  const selected = filters.capacity.length || filters.validity.length
  return (
    <div className='flex items-center'>
      <Button as={Fragment}>
        {() => (
          <button
            onClick={() => setOpen(true)}
            className={clsx(
              `px-2 py-2 md:px-4 md:py-2 rounded-full flex justify-center items-center gap-2 text-sm leading-5 font-semibold border`,
              disabled && 'border-gray-200 opacity-50',
              selected ? 'bg-white text-red-500 border-red-500' : !disabled ? 'bg-gray-200 text-gray-900 border-transparent hover:border-gray-400' : '',
            )}
            disabled={disabled}
            type='button'
          >
            <FunnelIconMobile className='block md:hidden size-5' aria-hidden='true' />
            <FunnelIcon className='hidden md:block size-4' aria-hidden='true' />
            <span className='hidden md:block'>{selected ? t('Search.Text.FiltersCount', { count: filters.capacity.length + filters.validity.length }) : t('Search.Text.Filters')}</span>
          </button>
        )}
      </Button>

      <Dialog open={open} as='div' className='relative z-50 focus:outline-none' onClose={() => setOpen(false)}>
        <DialogBackdrop
          transition
          className={`
          fixed inset-0 bg-black/30
          ease-in-out
          data-[closed]:opacity-0
          data-[enter]:duration-100 data-[enter]:data-[closed]:transition-opacity
          data-[leave]:duration-300 data-[leave]:data-[closed]:-transition-opacity
        `}
        />

        <div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
          <DialogPanel
            transition
            className={`
              relative rounded-md w-full max-w-md md:max-w-screen-sm space-y-4 bg-white shadow-sm
              ease-in-out data-[closed]:opacity-0
              data-[enter]:duration-100 data-[enter]:data-[closed]:translate-x-full
              data-[leave]:duration-300 data-[leave]:data-[closed]:translate-x-full
            `}
          >
            <div className='flex items-start justify-between p-4 md:p-6 !pb-4 border-b border-b-gray-200'>
              <div>
                <h5 className='text-xl font-medium text-slate-800'>{t('Search.Heading.Filters')}</h5>
                <p className='text-slate-500 text-sm font-light'>{t('Search.Text.FiltersDescription')}</p>
              </div>
              <button
                data-ripple-dark='true'
                data-dialog-close='true'
                className='relative h-8 max-h-[32px] w-8 max-w-[32px] select-none rounded-md text-center align-middle font-sans text-xs font-medium uppercase text-blue-gray-500 transition-all hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
                type='button'
                onClick={() => setOpen(false)}
              >
                <span className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform'>
                  <XMarkIcon className='block size-4' aria-hidden='true' />
                </span>
              </button>
            </div>

            <div className='flex flex-col space-y-6 divide-y divide-gray-200 px-4 lg:px-6'>
              <FilterList
                title={t('Search.Text.CapacityFilter')}
                description={t('Search.Text.CapacityFilterDescription')}
                icon={<CapacityIcon className='block size-5 text-gray-400' aria-hidden='true' />}
                options={capacityOptions}
                value={capacity}
                onChange={setCapacity}
              />
              <FilterList
                title={t('Search.Text.ValidityFilter')}
                description={t('Search.Text.ValidityFilterDescription')}
                icon={<ValidityIcon className='block size-5 text-gray-400' aria-hidden='true' />}
                options={validityOptions}
                value={validity}
                onChange={setValidity}
              />
            </div>

            <div className='flex flex-wrap items-center justify-between gap-2 p-4 md:p-6 text-blue-gray-500 mt-2 border-t border-t-gray-200'>
              <button
                className='text-sm text-slate-500 cursor-pointer'
                onClick={() => {
                  setCapacity([])
                  setValidity([])
                }}
              >
                {t('Search.Action.ClearAll')}
              </button>
              <button
                className={clsx(`rounded-md border py-2 px-4 
                  bg-red-500 text-white hover:bg-white hover:text-red-500 hover:border-red-500
                  text-center text-sm transition-all shadow-sm hover:shadow-lg                   
                `)}
                type='button'
                onClick={() => {
                  setOpen(false)
                  handleSetFilters({ validity, capacity })
                }}
              >
                {t('Search.Action.ShowResults')}
              </button>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </div>
  )
}

export default Filters
