import(/* webpackMode: "eager" */ "/codebuild/output/src2905821135/src/esimify/node_modules/flag-icons/css/flag-icons.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2905821135/src/esimify/src/app/[lang]/offers/header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2905821135/src/esimify/src/app/[lang]/offers/list.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2905821135/src/esimify/src/app/[lang]/offers/search-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2905821135/src/esimify/src/components/layout/footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2905821135/src/esimify/src/components/layout/navigation/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2905821135/src/esimify/src/i18n/translation-provider.tsx");
