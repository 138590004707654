import { Fragment } from 'react'
import { Button } from '@headlessui/react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { CapacityOptionItem, ValidityOptionItem } from '../../types'

interface FilterListProps {
  options: Array<ValidityOptionItem | CapacityOptionItem>
  title: string
  icon: React.ReactNode
  description?: string
  value: Array<ValidityOptionItem | CapacityOptionItem>
  onChange: (value: Array<ValidityOptionItem | CapacityOptionItem>) => void
}
const FilterList = ({ title, icon, description, options, value, onChange }: FilterListProps) => {
  const { t } = useTranslation()

  const handleChange = (option: ValidityOptionItem | CapacityOptionItem) => {
    const exist = value.some(item => item.value === option.value)
    if (exist) {
      onChange(value.filter(item => item.value !== option.value))
    } else {
      onChange([...value, option])
    }
  }

  return (
    <div className='pt-4 first:pt-0'>
      <div className='flex flex-col gap-2 md:gap-4'>
        <div className='flex flex-col justify-center'>
          <div className='flex items-center gap-1 text-lg font-medium'>
            {icon}
            {title}
          </div>
          <div className='md:pl-6 text-sm text-gray-400'>{description}</div>
        </div>
        <div className='md:pl-6 flex flex-wrap gap-x-4'>
          {options.map(option => {
            const selected = value.some(item => item.value === option.value)
            return (
              <Button as={Fragment} key={option.value}>
                {({ active, hover }) => (
                  <button
                    className={clsx(
                      `px-4 py-2 my-2 rounded-full flex justify-center items-center gap-2 text-sm leading-5 font-semibold border`,
                      'bg-white',
                      selected ? 'border-red-500 bg-red-500/10' : hover ? 'border-gray-400' : 'border-gray-200',
                    )}
                    type='button'
                    onClick={() => handleChange(option)}
                  >
                    {t(option.label)}
                  </button>
                )}
              </Button>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default FilterList
