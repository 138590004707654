import clsx from 'clsx'

interface OffersSkeletonProps {
  count?: number
}

function randomWidth(from: number, to: number): number {
  return Math.floor(Math.random() * (to - from + 1) + from)
}

const speed = (
  <div className='flex items-center justify-center size-7'>
    <div className='animate-pulse size-6 bg-skeleton-200 rounded-[6px]' />
  </div>
)

const name = <div className='w-9/12 py-1 h-5 animate-pulse bg-skeleton-200' />

const cardType = <div className='w-1/2 py-1 h-2 animate-pulse bg-skeleton-200' />

const attributes = <span className='inline-flex gap-x-1 items-center px-2 py-1 w-6/12 h-4 animate-pulse bg-skeleton-200' />

const icon = <span className='hidden min-w-6 h-6 xl:inline-block'></span>

const value = <span className='bg-skeleton-200 animate-pulse h-4' style={{ width: '65%' }} />

const OfferItemSkeleton = () => {
  const title = (
    <>
      <div className='flex items-center gap-x-1 lg:gap-x-2'>
        <div className='w-48'>{name}</div>
      </div>
      <div className='hidden md:block md:ml-8 lg:ml-9 text-sm text-gray-400'>
        <div className='w-1/2 py-1 h-2 animate-pulse bg-skeleton-200' />
      </div>
    </>
  )

  return (
    <div className='flex md:block h-[115px] md:h-[74px] xl:h-[82px] bg-white px-2 py-3 xl:px-4 xl:py-4 rounded-md z-40'>
      <div className={clsx('grid grid-cols-6 gap-y-1 md:gap-y-0 md:grid-cols-9')}>
        {/* Title - mobile colum*/}
        <div className='flex col-span-6 md:hidden'>
          <div className='flex flex-col flex-shrink'>{title}</div>
          <div className='flex flex-1 items-center justify-end flex-wrap gap-x-1 gap-y-1 md:justify-start'>{attributes}</div>
        </div>

        {/* Title - desktop column */}
        <div className='hidden md:flex col-span-2 md:col-span-3 flex-col'>
          <div className='flex flex-col justify-center gap-1.5 md:h-12'>
            <div className='flex items-center gap-x-1 lg:gap-x-2'>
              <div>{speed}</div>
              {name}
            </div>
            <div className='hidden md:block md:ml-8 lg:ml-9'>{cardType}</div>
          </div>
        </div>

        {/* Attributes - desktop colum*/}
        <div className='hidden md:flex col-span-4 md:col-span-2 flex-wrap gap-x-1 gap-y-1 justify-end md:justify-start md:items-center'>{attributes}</div>

        {/*Capacity column*/}
        <div className='col-span-2 md:col-span-1 flex items-center md:justify-start gap-x-2'>
          {icon}
          {value}
        </div>

        {/*Validity column*/}
        <div className='col-span-2 md:col-span-1 flex items-center md:justify-start gap-x-2'>
          {icon}
          {value}
        </div>

        {/*Price column*/}
        <div className='col-span-2 md:col-span-1 flex items-center justify-end md:justify-start gap-x-2'>
          {icon}
          {value}
        </div>

        {/* Card type column - mobile screen*/}
        <div className='col-span-2 flex items-center gap-x-2 md:hidden text-sm md:text-base'>
          <div className='flex items-center gap-x-2'>
            {icon}
            <div className='w-36 h-4 bg-skeleton-200' />
          </div>
        </div>

        {/* Countries column - mobile screen*/}
        <div className='col-span-2 flex items-center gap-x-2 md:hidden text-base md:text-base'></div>

        {/*Buy button*/}
        <div className='col-span-2 md:col-span-1 flex justify-end items-center'>
          <span className='w-16 lg:w-20 bg-skeleton-200 animate-pulse h-4' />
        </div>
      </div>
    </div>
  )
}

const OffersSkeleton = ({ count = 3 }: OffersSkeletonProps) => {
  return (
    <div>
      <div>
        {/*<div className='hidden md:block px-2 xl:px-4 pt-1 pb-2 text-gray-500 text-sm'>
          <div className={clsx('grid grid-cols-9')}>
            <div className='col-span-3 flex gap-x-2'>
              <span className='block w-7 h-1' />
              ...
            </div>
            <div className='col-span-2 flex justify-start'>...</div>
            <div className='flex items-center gap-x-2'>
              <span className='block min-w-6 h-1 md:hidden xl:inline-block' />
              ...
            </div>
            <div className='flex items-center gap-x-2'>
              <span className='block min-w-6 h-1 md:hidden xl:inline-block' />
              ...
            </div>
            <div className='flex items-center gap-x-2'>
              <span className='block min-w-6 h-1 md:hidden xl:inline-block' />
              ...
            </div>
            <div className=''></div>
          </div>
        </div>*/}
        <div className='grid gap-y-4 md:gap-y-6'>
          {new Array(count).fill(0).map((_, i) => (
            <OfferItemSkeleton key={i} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default OffersSkeleton
