import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { Trans, useTranslation } from 'react-i18next'

import { PlanDetails } from '@/types/plan'
import CountryFlag from '@/components/country-flag/country-flag'

import { filterCountries } from './helpers'

interface CountriesListProps {
  plan?: PlanDetails
  open: boolean
  onClose: () => void
}

const CountriesList = ({ plan, open, onClose }: CountriesListProps) => {
  const { t } = useTranslation()
  const [query, setQuery] = useState<string>('')

  const handleClose = () => {
    setQuery('')
    onClose()
  }

  const list = plan?.countries?.filter(country => filterCountries(country.name.toLowerCase(), query.toLowerCase()))
  return (
    <Dialog open={open} as='div' className='relative z-50 focus:outline-none' onClose={handleClose}>
      <DialogBackdrop
        transition
        className={`
          fixed inset-0 bg-black/30
          ease-in-out
          data-[closed]:opacity-0
          data-[enter]:duration-100 data-[enter]:data-[closed]:transition-opacity
          data-[leave]:duration-300 data-[leave]:data-[closed]:-transition-opacity
        `}
      />
      <div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
        <DialogPanel
          transition
          className={`
              flex flex-col relative rounded-md w-full max-h-full max-w-full md:max-w-3xl space-y-4 bg-white shadow-sm
              ease-in-out data-[closed]:opacity-0
              data-[enter]:duration-100 data-[enter]:data-[closed]:translate-x-full
              data-[leave]:duration-300 data-[leave]:data-[closed]:translate-x-full
            `}
        >
          <div className='flex items-start justify-between p-4 md:p-6 !pb-4 border-b border-b-gray-200'>
            <div>
              <h5 className='text-xl font-medium text-slate-800'>{t('Search.Heading.AvailableCountries', { name: plan?.formatedPlan })}</h5>
            </div>
            <button
              data-ripple-dark='true'
              data-dialog-close='true'
              className='relative h-8 max-h-[32px] w-8 max-w-[32px] select-none rounded-md text-center align-middle font-sans text-xs font-medium uppercase text-blue-gray-500 transition-all hover:bg-blue-gray-500/10 active:bg-blue-gray-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none'
              type='button'
              onClick={onClose}
            >
              <span className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform'>
                <XMarkIcon className='block size-4' aria-hidden='true' />
              </span>
            </button>
          </div>

          <div className='flex flex-col flex-auto gap-4 lg:gap-6 px-4 lg:px-6 min-h-96 overflow-y-scroll'>
            <p className='text-gray-900 text-sm'>
              <Trans i18nKey='Search.Text.AvailableCountriesDescription' components={[<strong key='0' />]} values={{ name: plan?.formatedPlan, count: plan?.countries?.length }} />
            </p>

            <div className='w-full max-w-sm min-w-[200px]'>
              <div className='relative flex items-center'>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' className='absolute w-5 h-5 top-2.5 left-2.5 text-slate-600'>
                  <path
                    fillRule='evenodd'
                    d='M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z'
                    clipRule='evenodd'
                  />
                </svg>

                <input
                  className='w-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md pl-10 pr-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow'
                  placeholder={t('Search.Text.SearchAvailableCountries')}
                  value={query}
                  onChange={e => setQuery(e.target.value)}
                />
              </div>
            </div>

            <div className='grid grid-cols-2 md:grid-cols-4 gap-4 lg:gap-6'>
              {list?.map((country, index) => (
                <div key={index} className='flex items-center gap-2 p-3 border border-gray-200 rounded-md'>
                  <CountryFlag country={country} />
                  <span className='text-sm text-gray-900 truncate'>{country.name}</span>
                </div>
              ))}
            </div>
          </div>

          <div className='flex flex-wrap items-center justify-end gap-2 p-4 md:p-6 text-blue-gray-500 mt-2 border-t border-t-gray-200'>
            <button
              className={clsx(`rounded-md border py-2 px-4 
                  bg-white text-red-500 border-red-500
                  text-center text-sm transition-all shadow-sm hover:shadow-lg                   
                `)}
              type='button'
              onClick={handleClose}
            >
              {t('Search.Action.Close')}
            </button>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  )
}

export default CountriesList
