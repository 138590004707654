import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { ChevronDownIcon } from '@heroicons/react/16/solid'

import { sortOptions } from '../constants'
import { SortOptionItem } from '../types'

interface SortByProps {
  loading: boolean
  count: number
  showing: number
  bestOffers: boolean
  sortBy: SortOptionItem
  setSort: (sort: SortOptionItem) => void
}

const SortBy = ({ loading, count, bestOffers, showing, sortBy, setSort }: SortByProps) => {
  const { t } = useTranslation()

  let displayText
  if (bestOffers) {
    displayText = t('Search.Text.HighlightedOffersCount', { count })
  } else if (showing !== count) {
    displayText = t('Search.Text.DisplayWhenAppliedFilters', { showing, count })
  } else {
    displayText = t('Search.Text.DisplayWithoutAppliedFilters', { count })
  }

  return (
    <div className='flex justify-between lg:justify-end'>
      <div className='flex items-center justify-start lg:justify-end min-w-40 text-sm text-gray-900 mr-0 lg:mr-4'>{!loading && <span>{displayText}</span>}</div>
      <div className='flex justify-end items-center gap-2 md:gap-4'>
        <Menu as='div' className='relative'>
          <div>
            <MenuButton as={Fragment}>
              {({ open, hover }) => (
                <button
                  className={clsx(
                    `min-w-40 px-4 py-2 rounded-full flex justify-between items-center gap-2 text-sm leading-5 font-semibold bg-gray-200 border`,
                    open ? 'border-gray-400' : 'text-black border-transparent',
                    !open && hover && 'hover:border-gray-400',
                    (!showing || loading) && 'opacity-50 pointer-events-none',
                  )}
                  type='button'
                  aria-haspopup='menu'
                  aria-expanded={open}
                >
                  <span className='sr-only'>{t('Search.Text.SortBy')}</span>
                  {t(sortBy.label)}
                  <ChevronDownIcon className='size-4' />
                </button>
              )}
            </MenuButton>
          </div>
          <Transition
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <MenuItems className='z-[51] absolute right-0 mt-1 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
              {sortOptions.map(sort => {
                return (
                  <MenuItem as='div' className={clsx('cursor-pointer', sort.divider && 'border-b border-b-gray-200')} onClick={() => setSort(sort)} key={sort.value}>
                    {({ focus }) => (
                      <div className={clsx(`flex flex-col px-4 py-2`, focus && 'bg-gray-100', sort.value === sortBy.value && 'bg-gray-200')}>
                        <div className={clsx(`text-sm font-medium`)}>{t(sort.label)}</div>
                      </div>
                    )}
                  </MenuItem>
                )
              })}
            </MenuItems>
          </Transition>
        </Menu>
      </div>
    </div>
  )
}

export default SortBy
