import clsx from 'clsx/lite'

interface MainProps {
  children: React.ReactNode
  className?: string
}

const Main = ({ children, className }: MainProps) => {
  return <main className={clsx('flex flex-col flex-1', className)}>{children}</main>
}

export default Main
