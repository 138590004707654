import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

interface TableHeaderProps {
  showCountriesColumn: boolean
}
const TableHeader = ({ showCountriesColumn }: TableHeaderProps) => {
  const { t } = useTranslation()
  return (
    <div className='hidden md:block px-2 xl:px-4 pt-1 pb-2 text-gray-500 text-sm'>
      <div className={clsx('grid', showCountriesColumn ? 'grid-cols-10' : 'grid-cols-9')}>
        <div className='col-span-3 flex gap-x-2'>
          <span className='block w-7 h-1' />
          {t('OfferItem.Heading.Name')}
        </div>
        <div className='col-span-2 flex justify-start'>{t('OfferItem.Heading.Attributes')}</div>
        {showCountriesColumn && (
          <div className='flex items-center gap-x-2'>
            <span className='block min-w-6 h-1 md:hidden xl:inline-block' />
            {t('OfferDetails.Heading.Countries')}
          </div>
        )}
        <div className='flex items-center gap-x-2'>
          <span className='block min-w-6 h-1 md:hidden xl:inline-block' />
          {t('OfferDetails.Heading.Capacity')}
        </div>
        <div className='flex items-center gap-x-2'>
          <span className='block min-w-6 h-1 md:hidden xl:inline-block' />
          {t('OfferDetails.Heading.Validity')}
        </div>
        <div className='flex items-center gap-x-2'>
          <span className='block min-w-6 h-1 md:hidden xl:inline-block' />
          {t('OfferDetails.Heading.Price')}
        </div>
        <div className=''></div>
      </div>
    </div>
  )
}

export default TableHeader
