'use client'

import { XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'

import { Country } from '@/types'
import CountryFlag from '@/components/country-flag/country-flag'

interface PopularCountriesProps {
  country: Country
  handleChange: (country: Country) => void
}

const CountryFilterItem = ({ country, handleChange }: PopularCountriesProps) => {
  return (
    <div className={clsx(`flex justify-center items-center max-w-72 px-2 !pr-2 py-1.5 bg-white rounded-full gap-2 text-sm leading-5 font-semibold border border-gray-200 text-black`)}>
      <CountryFlag country={country} />
      <span className='truncate'>{country.name}</span>
      <button
        type='button'
        className='inline-flex items-center p-1 text-sm text-gray-400 bg-transparent rounded-sm hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-gray-300'
        data-dismiss-target='#badge-dismiss-dark'
        aria-label='Remove'
        onClick={() => handleChange(country)}
      >
        <XMarkIcon className='size-4' />
      </button>
    </div>
  )
}

export default CountryFilterItem
