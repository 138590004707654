'use client'

import { XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'

import { OptionItem } from '@/types/common'

interface OtherFilterItemProps {
  text: string
  option: OptionItem
  handleChange: (option: OptionItem) => void
}

const OtherFilterItem = ({ text, option, handleChange }: OtherFilterItemProps) => {
  return (
    <div className={clsx(`flex justify-center items-center px-4 pr-2 py-1.5 bg-white rounded-full gap-2 text-sm leading-5 font-semibold border border-gray-200 text-black`)}>
      {text}
      <button
        type='button'
        className='inline-flex items-center p-1 text-sm text-gray-400 bg-transparent rounded-sm hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-gray-300'
        data-dismiss-target='#badge-dismiss-dark'
        aria-label='Remove'
        onClick={() => handleChange(option)}
      >
        <XMarkIcon className='size-4' />
      </button>
    </div>
  )
}

export default OtherFilterItem
